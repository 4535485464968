@media (max-width: 768px){   
  .post {
    width: 90vw;
    position: relative;
    top: 90px;
    .postdesktop{
      display: none;
    }

    .postmobile{
      
      box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      border-radius: 20px;
      background-color: white;
      margin-bottom: 20px;
      
      

      .container {
        padding: 20px;

        .user {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .userInfo {
            display: flex;
            gap: 20px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }

            .details {
              display: flex;
              flex-direction: column;

              .name {
                font-weight: 500;
              }

              .date {
                font-size: 12px;
              }
            }
          }
        }

        .content {
          margin: 20px 0px;

          img {
            width: 100%;
            max-height: 500px;
            object-fit: cover;
            margin-top: 20px;
          }
        }

        .info {
          display: flex;
          align-items: center;
          gap: 20px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (min-width: 769px){   
  .post {
    position: relative;
    top: 90px;
    left: 0px;
    
    .postdesktop{
      box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      border-radius: 20px;
      background-color: white;
      width: 50vw;
      margin-right: auto;
      margin-left: 19.5vw;
      margin-bottom: 20px;
      
      
      .container {
        padding: 20px;

        .user {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .userInfo {
            display: flex;
            gap: 20px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }

            .details {
              display: flex;
              flex-direction: column;

              .name {
                font-weight: 500;
              }

              .date {
                font-size: 12px;
              }
            }
          }
        }

        .content {
          margin: 20px 0px;

          img {
            width: 100%;
            max-height: 500px;
            object-fit: cover;
            margin-top: 20px;
          }
        }

        .info {
          display: flex;
          align-items: center;
          gap: 20px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }

    .postmobile{
      display: none;
    }
  }
}