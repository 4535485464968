@media (max-width: 768px){   
    .jobs::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
  
    .jobs{
        .jobsdesktop{
            display: none;
        } 

        .jobsmobile::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }

        .jobsmobile{
            background-color: lightgrey;
            min-width: 100%;
            margin-left: 0;
            margin-right: 0;
            overflow-x: hidden;


            .body::-webkit-scrollbar { 
                display: none;  /* Safari and Chrome */
            }

            .body{
                display: flex;
                justify-content: space-between;
                width: 98.5vw;
                height: 115vh;
                
                .leftbarmobile{
                    z-index: 2;
                }

                .joblist{
                    z-index: 1;
                    margin-left: auto;
                    margin-right: auto;
                    height: fit-content;
                }
                
            }
        } 
    }
}

@media (min-width: 769px){ 
    .jobs{

        .jobsdesktop::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }

        .jobsdesktop{
            background-color: lightgrey;
            min-width: 100%;
            margin-left: 0;
            margin-right: 0;
            overflow-x: hidden;

           
            .body{
                display: flex;
                justify-content: space-between;
                width: 50vw;
                height: 100vh;

                .leftbar{
                    z-index: 2;
                }
                .joblist{
                    z-index: 1;
                    
                }
                .rightbar{
                    z-index: 0;
                }
            }
        } 

        .jobsmobile{
            display: none;
         }
    }
}