@media (max-width: 768px){     
    .profile{
        background-color: lightgrey;
        min-width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
        

        .body{
            display: flex;
            justify-content: space-between;
            

            .leftbar{
                z-index: 2;
            }

            .middle::-webkit-scrollbar { 
                display: none;  /* Safari and Chrome */
            }

            .middle{
                z-index: 1;
                margin-left: auto;
                margin-right: auto;
                height: 140vh;
                overflow-y: scroll;
               

                .contain{
                    position: relative;
                    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
                    border-radius: 20px;
                    background-color: white;
                    width: 90vw;
                    margin-top: 40px;
                    top: 50px;
                    
                    .uInfo{
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .left{
                            //padding: 3vw;
                            img{
                                width: 25vw;
                                max-width: 300px;
                                border-radius: 50%;
                                overflow: hidden;
                                object-fit: cover;
                                aspect-ratio: 1/1;
                                margin-left: 20px;
                            }
                        }
                        .right{
                            //padding-top: 1vh;
                            
                            .info{
                                text-align: left;
                                line-height: 175%;
                                h1{
                                    font-size: 25px;
                                    
                                    //padding-top: 5vh;
                                    //padding-bottom: 5px;
                                }
                                h2{
                                    color: green;
                                    font-size: 18px;
                                    //padding-top: 5px;
                                    font-weight: 500;
                                    //padding-bottom: 5px;
                                }
                                h3{
                                    color: grey;
                                    font-weight: 500;
                                    font-size: 15px;
                                    //padding-top: 5px;
                                    //padding-bottom: 5px;
                                } 
                                
                            }
                            button{
                                margin-top: 10px;
                                width: 20vw;
                                max-width: 140px;
                                background-color: green;
                                color: white;
                                border: none;
                                height: 25px;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
            .rightbar{
                display: none;
                
            }
        }
        
    }
}

@media (min-width: 769px){     
    .profile{
        background-color: lightgrey;
        min-width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
        padding: 0;
        margin:0;
        
        .body{
            display: flex;
            justify-content: space-between;
            width: 50vw;
            height: 115vh;

            .leftbar{
                z-index: 2;
            }

            .middle::-webkit-scrollbar { 
                display: none;  /* Safari and Chrome */
            }

            .middle{
                z-index: 1;
                //height: 110vh;
                //overflow-y: scroll;
                
                
                .contain{
                    position: relative;
                    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
                    border-radius: 20px;
                    background-color: white;
                    width: 50vw;
                    margin-top: 40px;
                    left: 19.5vw;
                    top: 50px;
                    
                    .uInfo{
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .left{
                            //padding: 3vw;
                            img{
                                width: 20vw;
                                max-width: 300px;
                                border-radius: 50%;
                                overflow: hidden;
                                object-fit: cover;
                                aspect-ratio: 1/1;
                                margin-left: 1vw;
                            }
                        }
                        .right{
                            //padding-top: 1vh;
                            .info{
                                text-align: left;
                                line-height: 175%;
                                h1{
                                    font-size: 30px;
                                    
                                    //padding-top: 5vh;
                                    //padding-bottom: 5px;
                                }
                                h2{
                                    color: green;
                                    font-size: 20px;
                                    //padding-top: 5px;
                                    font-weight: 500;
                                    //padding-bottom: 5px;
                                }
                                h3{
                                    color: grey;
                                    font-weight: 500;
                                    font-size: 16px;
                                    //padding-top: 5px;
                                    //padding-bottom: 5px;
                                } 
                                
                            }
                            button{
                                margin-top: 10px;
                                width: 15vw;
                                max-width: 140px;
                                background-color: green;
                                color: white;
                                border: none;
                                height: 25px;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
            .rightbar{
                z-index: 0;
            }
        }
        
    }
}
