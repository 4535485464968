.mission{
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow-x: hidden;
    .body{
        text-align: center;
        h1{
            font-size: 25pt;
            font-weight: 400;
            padding-top: 50pt;
            color:#008037;
            width:100%;
        }

        p{
            text-align: center;
            font-size: 16pt;
            line-height: 150%;
            padding-bottom: 5vh;
            width: 70vw;
            margin-left: auto;
            margin-right: auto;
            padding-top: 14px;
        }

        img{
            max-width: 70vw;
            margin-bottom: 100px;
        }
    }
}

