@media (max-width: 768px){ 
    .about{
        text-align: center;
        min-width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
        
        .top{
            width: 100%; 

            h1{
                text-align: center;
                font-size: 25pt;
                font-weight: 400;
                padding-top: 50pt;
                color:#008037;
                margin-left: auto;
                margin-right: auto;
            }

            p{
                text-align: center;
                font-size: 16pt;
                padding-top: 14px;
                max-width: 70%;
                margin-left: auto;
                margin-right: auto;
                line-height: 24pt;
            }

            img{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 40px;
                height: auto;
                padding-top: 30px;
            }
        }

        .bottomdesktop{
            display: none;
        }

        .bottommobile{
            width: 100%;
            text-align: center;

            h1{
                text-align: center;
                font-size: 25pt;
                font-weight: 400;
                padding-top: 20pt;
                color:#008037;
            }

            h2{
                font-size: 20px;
                padding-top: 3%;
            }

            p{
                font-size: 16px;
                line-height: 150%;
                width: 70vw;
                margin-left: auto;
                margin-right: auto;
                padding-top: 3%;
            }
                    
            img{
                width:60vw;
                padding-top: 10%;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

@media (min-width: 769px){ 
    .about{
        text-align: center;
        min-width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
        
        .top{
            width: 100%; 

            h1{
                text-align: center;
                font-size: 25pt;
                font-weight: 400;
                padding-top: 50pt;
                color:#008037;
                margin-left: auto;
                margin-right: auto;
            }

            p{
                text-align: center;
                font-size: 16pt;
                padding-top: 14px;
                max-width: 70%;
                margin-left: auto;
                margin-right: auto;
                line-height: 24pt;
            }

            img{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 40px;
                height: auto;
                padding-top: 30px;
            }
        }

        .bottomdesktop{
            width: 100%;

            h1{
                text-align: center;
                font-size: 25pt;
                font-weight: 400;
                padding-top: 20pt;
                color:#008037;
            }

            table{
                th{
                    font-size: 20px;
                    padding-top: 1%;
                    text-align: left;
                    padding-left: 5%;
                }
                
                td{
                    padding-top: 1%;
                    
                    img{
                        max-width: 350px;
                        max-height: 350px;
                        padding-top: 3%;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                td.description{
                    line-height: 150%;
                    width: 30%;
                    font-size: 16px;
                    text-align: left;
                    padding-left: 5%;
                    padding-right: 5%;
                }
            }
        }
        .bottommobile{
            display: none;
        }
    }
}
