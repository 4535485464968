@media (max-width: 768px){      
    .leftbardesktop{
        display: none;
    }

    .bottombarmobile{
        width: 100vw;
        height: 50px;
        position: fixed;
        background-color: white;
        bottom: 0;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 7vw;
        align-items: center;
        //margin-bottom: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        
        border-top: 1px solid lightgrey;
    
        button.newpost{
            color: white;
            background-color: #008037;
            border: none;
            border-radius: 50%;
            padding-left: 12px;
            padding-right: 12px;
            cursor: pointer;
            font-size: 40px;
            display: flex; 
            align-items: center;
            justify-content: center;
            margin-bottom: 2px;
        }
    
        img{
            max-width: 35px;
            min-width: 35px;
        }
    
        h3{
            font-size: 11px;
            font-weight: 500;
            line-height: 200%;
            text-align: center;
        }  
    
        .container{
            position: absolute;
            bottom: 0px;
            left: 0vh;
            height: 100vh;
            width: 100vw;
            background: rgba(black, .80);
            
        
            .new-post {
                position: relative;
                justify-content: center;
                top: 15vh;
                left: 12vw;
                z-index: 2;
                background-color: white;
                width: 70vw;
                height: 45vh;
                padding: 20px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                overflow: auto;
                border-radius: 20px;
                    
                h2 {
                margin-top: 5px;
                margin-bottom: 25px;
                }
            
                form {
                display: flex;
                flex-direction: column;
                gap: 15px;
            
                input.title,
                textarea {
                    font-size: 16px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    resize: none;
                    width: 66vw;
                }
    
                input.post,
                textarea {
                    font-size: 16px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    resize: none;
                    width: 66vw;
                    height: 120px;
                    top: 0px;
                }
            
                input[type="file"] {
                    display: none;
                }
            
                label {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
            
                .file-input {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                }
            
                .file-input button {
                    font-size: 14px;
                    padding: 8px 12px;
                    border: none;
                    background-color: #008037;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                }
            
                .file-input p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                }
            
                label.file {
                    font-size: 16px;
                    padding: 10px;
                    border: none;
                    background-color: #008037;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                    align-self: flex-end;
                    width: fit-content;
                }
    
                button.submit {
                    font-size: 16px;
                    padding: 10px;
                    border: none;
                    background-color: #008037;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                    align-self: flex-end;
                    width: fit-content;
                }
                }
            }
        }
    }
}

@media (min-width: 769px){      
    .leftbardesktop{
        height: 90vh;
        width: 15vw;
        position: fixed;
        background-color: white;
        top: 71px;
        left: 0px;
        display: flex;
        flex-direction: column;
        gap: 10vh;
        align-items: center;
        padding-top: 50px;
        padding-right: 0;

        button{
            color: white;
            background-color: #008037;
            border: none;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            cursor: pointer;
            font-size: 40px;
            display: flex; 
            align-items: center;
            justify-content: center;
        }

        img{
            max-width: 35px;
            min-width: 35px;
        }

        h3{
            font-size: 11px;
            font-weight: 500;
            line-height: 200%;
            text-align: center;
        }  

        .container{
            position: absolute;
            bottom: 20px;
            left: 0vh;
            height: 110vh;
            width: 100vw;
            background: rgba(black, .80);
            
        
            .new-post {
                position: absolute;
                top: 15vh;
                left: 25vw;
                z-index: 2;
                background-color: white;
                width: 45vw;
                height: 45vh;
                padding: 20px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                overflow: auto;
                border-radius: 20px;
                    
                h2 {
                margin-top: 5px;
                margin-bottom: 25px;
                }
            
                form {
                display: flex;
                flex-direction: column;
                gap: 15px;
            
                input.title,
                textarea {
                    font-size: 16px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    resize: none;
                    width: 40vw;
                }

                input.post,
                textarea {
                    font-size: 16px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    resize: none;
                    width: 40vw;
                    height: 120px;
                    top: 0px;
                }
            
                input[type="file"] {
                    display: none;
                }
            
                label {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
            
                .file-input {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                }
            
                .file-input button {
                    font-size: 14px;
                    padding: 8px 12px;
                    border: none;
                    background-color: #008037;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                }
            
                .file-input p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                }
            
                label.file {
                    font-size: 16px;
                    padding: 10px;
                    border: none;
                    background-color: #008037;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                    align-self: flex-end;
                    width: fit-content;
                }

                button.submit {
                    font-size: 16px;
                    padding: 10px;
                    border: none;
                    background-color: #008037;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                    align-self: flex-end;
                    width: fit-content;
                }
                }
            }
        }
    }

    .bottombarmobile{
        display: none;
    }
}