.careers{
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow-x: hidden;
    text-align: center;
    .body{
        height: 60vh;
        h1{
            font-size: 25pt;
            font-weight: 400;
            padding-top: 50pt;
            color:#008037;
            width:100%;
        }

        p{
            text-align: center;
            font-size: 16pt;
            line-height: 150%;
            padding-bottom: 2%;
            width: 55%;
            margin-left: auto;
            margin-right: auto;
            padding-top: 5vh;
        }
    }
}