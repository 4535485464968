@media (max-width: 768px){     
    .footer{
        width: 100vw;
        //min-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 10vh;    
        .links{
            
            display: grid;
            //justify-content: space-between;
            width: 70vw;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            //padding-top: 5vh;
            min-width: 650px;
            

            .left{
                position: relative;
                display: inline;
                //align-items: center;
                margin-right: auto;
                margin-left: auto;
                padding-top: 100px;
                line-height: 300%;
                white-space: nowrap;
                text-align: left;

                h3{
                    color: #008037;
                    text-decoration: none;
                    font-size: 8pt;
                    font-weight: 700;
                    padding-left: 2vw;
                    padding-right: 10vw;
                    
                }
            }
                        
            .center{
                margin-bottom: 100px;
                position: absolute;
                display: flex;
                align-items: center;
                margin-right: auto;
                margin-left: auto;

                h3{
                    color: #008037;
                    text-decoration: none;
                    font-size: 16pt;
                    font-weight: 500;
                    padding-left: 4px;
                    
                }
                        
                img{
                    margin-left: 5vh;
                    max-width: 25px;
                    min-width: 25px;
                    padding-right: 4px;
                }
            }

            .right{
                position: absolute;
                margin-top: 100px;
                display: inline;
                align-items: center;
                line-height: 300%;
                width: 30%;
                white-space: nowrap;
                text-align: left;

                h3{
                    color: #008037;
                    text-decoration: none;
                    font-size: 8pt;
                    font-weight: 700;
                    padding-left: 15vw;
                    margin-right: 2vw;
                    min-width: fit-content; 
                } 
                
            }  
        } 

        .tag{
            width: 100%;
            h3{
                border-top: 1px solid grey;
                padding-top:3vh;
                color: grey;
                font-size: 7pt;
                text-align: center;
                margin-top: 3vh;
                margin-bottom: 5%;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 769px){     
    .footer{
        width: 100vw;
            
        .links{
            border-bottom: 1px solid grey;
            display: flex;
            justify-content: space-between;
            width: 70%;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            padding-top: 5%;
            min-width: 650px;
            padding-bottom: 0.5%;

            .left{
                display: flex;
                align-items: center;
                margin-right: auto;
                margin-left: auto;
                width: 30%;
                h3{
                    color: #008037;
                    text-decoration: none;
                    font-size: 8pt;
                    font-weight: 700;
                    padding-left: 2vw;
                    padding-right: 2vw;
                }
            }
                        
            .center{
                display: flex;
                align-items: center;
                margin-right: auto;
                margin-left: auto;

                h3{
                    color: #008037;
                    text-decoration: none;
                    font-size: 16pt;
                    font-weight: 500;
                    padding-left: 4px;
                    
                }
                        
                img{
                max-width: 25px;
                min-width: 25px;
                padding-right: 4px;
                }
            }

            .right{
                display: flex;
                align-items: center;
                direction: rtl;
                margin-right: auto;
                margin-left: auto;
                width: 30%;

                

                h3{
                    color: #008037;
                    text-decoration: none;
                    font-size: 8pt;
                    font-weight: 700;
                    padding-left: 2vw;
                    margin-right: 2vw;
                    min-width: fit-content; 
                } 
                
            }  
        } 

        .tag{
            width: 100%;
            h3{
                color: grey;
                font-size: 7pt;
                text-align: center;
                margin-top: 30px;
                margin-bottom: 5%;
                font-weight: 500;
            }
        }
    }
}