@media (max-width: 768px){ 
    .register{ 
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
        h1{
            font-size: 25pt;
            font-weight: 400;
            padding-top: 50pt;
            color:#008037;
            width:100%;
            text-align: center;
            padding-bottom: 3%;
        }
        .loginBox{
            padding: 5%;
            width: 60vw;
            border: 1px solid grey;  
            margin-left: 40px;
            max-height: 410px;
            min-height: 410px;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            
            form{
                table{   
                    
                    tr{ 
                        td{
                            input{
                                width: 55vw; 
                                max-width: 450px;   
                                height: 30px;  
                                border: none;   
                                padding-left: 8px;  
                                border: 1px solid grey;
                                font-size: 17px; 
                                border-radius: 5px;
                                margin-bottom: 40px;
                            }
                        }
                    }
                    tr.names{
                        td{
                            input{
                                width: 25vw; 
                                max-width: 200px;   
                                height: 30px;  
                                border: none;   
                                border: 1px solid grey;
                                font-size: 17px; 
                                border-radius: 5px;
                                margin-bottom: 40px;
                            }
                        }
                    }
                }
                button{
                    width: 20vw;
                    max-width: 150px;
                    height: 30px;  
                    border: none;  
                    border-radius: 5px;
                    color: white;  
                    background-color: #008037;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 17px; 
                    cursor: pointer;
                }
            }
            
            h3{
                padding-top: 60px;
                padding-bottom: 2%;
                margin-left: auto;
                margin-right: auto;
                color: #008037;  
                font-weight: 500;
                font-size: 12pt;
            }
            
            button.loginButton{
                width: 20vw;
                max-width: 150px;  
                height: 30px;  
                border: none;  
                border-radius: 5px;  
                color: #008037;  
                border: 1px solid #008037; 
                margin-left: auto;
                margin-right: auto;
                font-size: 17px; 
                cursor: pointer;
                margin-top: 20px;
            }
        }
    }
}

@media (min-width: 769px){ 
    .register{ 
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
        h1{
            font-size: 25pt;
            font-weight: 400;
            padding-top: 50pt;
            color:#008037;
            width:100%;
            text-align: center;
            padding-bottom: 3%;
        }
        .loginBox{
            padding: 5%;
            width: 60vw;
            max-width: 500px;
            border: 1px solid grey;  
            margin-left: 40px;
            max-height: 410px;
            min-height: 410px;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            
            form{
                table{   
                    margin-left: auto;
                    margin-right: auto;
                    tr{ 
                        td{
                            input{
                                width: 50vw; 
                                max-width: 450px; 
                                height: 30px;  
                                border: none;   
                                padding-left: 8px;  
                                border: 1px solid grey;
                                font-size: 17px; 
                                border-radius: 5px;
                                margin-bottom: 40px;
                            }
                        }
                    }
                    tr.names{
                        td{
                            input{
                                width: 25vw;
                                max-width: 215px;  
                                height: 30px;  
                                border: none;   
                                border: 1px solid grey;
                                font-size: 17px; 
                                border-radius: 5px;
                                margin-bottom: 40px;
                            }
                        }
                    }
                }
                button{
                    width: 20vw;
                    max-width: 150px;  
                    height: 30px; 
                    border: none;  
                    border-radius: 5px;
                    color: white;  
                    background-color: #008037;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 17px; 
                    cursor: pointer;
                }
            }
            
            h3{
                padding-top: 60px;
                padding-bottom: 2%;
                margin-left: auto;
                margin-right: auto;
                color: #008037;  
                font-weight: 500;
                font-size: 12pt;
            }

            button.googleSignIn{
                transition: background-color .3s, box-shadow .3s;
                padding: 12px 16px 12px 42px;
                border: 1px solid lightgray;
                border-radius: 3px;
                box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
                background-color: white;
                background-repeat: no-repeat;
                background-position: 12px 11px;
                margin-top: 20px;
                
                &:hover {
                  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
                }
                
                &:active {
                  background-color: #eeeeee;
                }
                
                &:focus {
                  outline: none;
                  box-shadow: 
                    0 -1px 0 rgba(0, 0, 0, .04),
                    0 2px 4px rgba(0, 0, 0, .25),
                    0 0 0 3px #c8dafc;
                }
                
                &:disabled {
                  filter: grayscale(100%);
                  background-color: #ebebeb;
                  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
                  cursor: not-allowed;
                } 
            }
            
            button.loginButton{
                width: 20vw;
                max-width: 150px;    
                height: 30px; 
                border: none;  
                border-radius: 5px;  
                color: #008037;  
                border: 1px solid #008037; 
                margin-left: auto;
                margin-right: auto;
                font-size: 17px; 
                cursor: pointer;
                margin-top: 20px;
            }
        }
    }
}