.contact{ 
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow-x: hidden;
    
    h1{
        font-size: 25pt;
        font-weight: 400;
        padding-top: 50pt;
        color:#008037;
        width:100%;
        text-align: center;
        padding-bottom: 3%;
    }

    .contactbox{
        padding: 5%;
        width: 70vw;
        max-width: 500px;
        border: 1px solid grey;  
        margin-left: 40px;
        max-height: 400px;
        min-height: 400px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        
        form{
            table{   
                margin-left: auto;
                margin-right: auto;
                tr{ 
                    td{
                        input{
                            width: 60vw; 
                            max-width: 500px; 
                            height: 30px;  
                            border: none;   
                            padding-left: 8px;  
                            border: 1px solid grey;
                            font-size: 17px; 
                            border-radius: 5px;
                            margin-bottom: 40px;
                        }

                        input.message{
                            width: 60vw; 
                            max-width: 500px; 
                            height: 150px;  
                            border: none;   
                            border: 1px solid grey;
                            font-size: 17px; 
                            border-radius: 5px;
                            margin-bottom: 40px;

                        }
                    }
                }

                tr.names{
                    td{
                        input{
                            width: 28vw; 
                            max-width: 240px; 
                            height: 30px;  
                            border: none;   
                            border: 1px solid grey;
                            font-size: 17px; 
                            border-radius: 5px;
                            margin-bottom: 40px;
                        }
                    }
                }
            }
            
            button{
                width: 30%;  
                height: 30px;  
                border: none;  
                border-radius: 5px;
                color: white;  
                background-color: #008037;
                margin-left: auto;
                margin-right: auto;
                font-size: 17px; 
                cursor: pointer;
            }
        }
    }
}
