.rightbar{
    height: 95vh;
    width: 25vw;
    position: absolute;
    float: right;
    margin-top: 20px;
    top: 25px;
    right: 1px;
    overflow-y: hidden;
    
    .trending{
        border-radius: 10px;
        background-color: white;
        margin-right: 20px;
        margin-bottom: 10px;
        padding: 10px;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        
        h2{
            font-size: 12pt;
            color: #008037;
            padding-top: 5pt;
            padding-bottom: 5pt;
        }

        h3{
            font-size: 10pt;
            padding-top: 2pt;
            padding-bottom: 2pt;
            font-weight: 500;
            padding-left: 5pt;
        }
    }

    .suggested{
        border-radius: 10px;
        background-color: white;
        margin-right: 20px;
        margin-bottom: 10px;
        padding: 10px;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        
        h2{
            font-size: 12pt;
            color: #008037;
            padding-top: 5pt;
            padding-bottom: 8pt;
            
        }

        .person{
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            
            img{
                width: 45px;
                height: 45px;
                border-radius: 50%;
                object-fit: cover;
                padding-left: 5pt;
            }
            
            h3{
                padding-left: 2vw;
                font-size: 10pt;
                padding-top: 2pt;
                padding-bottom: 2pt;
                font-weight: 500;
            }
        }
    }

    .note{
        border-radius: 10px;
        background-color: white;
        margin-right: 20px;
        margin-bottom: 10px;
        padding: 10px;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        text-align: center;
        line-height: 150%;
        
        h2{
            font-size: 14pt;
            color: #008037;
            padding-top: 5pt;
            padding-bottom: 5pt;
        }

    }
    
}