@media (max-width: 768px){
    .landing{
        min-width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
        .top{
            width: 100%; 
            display:inline;
            .left{
                h1{
                    font-size: 25pt;
                    font-weight: 700;
                    line-height: 150%;
                    color: #008037;
                    padding-top: 10%;
                    margin-left: auto;
                    margin-right: auto;
                    width: 80vw;
                    text-align: center;
                }
            }

            .right{
                .loginbox{ 
                    margin-top: 5vh;
                    padding: 5%;
                    width: 70vw;
                    border: 1px solid grey;  
                    margin-left: auto;
                    margin-right: auto;
                    max-height: 360px;
                    min-height: 360px;
                    border-radius: 10px;
                    text-align: center;

                    h2{
                        padding-top: 5%;
                        margin-left: auto;
                        margin-right: auto;
                        color: #008037;  
                    }
                    form{
                        input{
                            width: 60vw;  
                            height: 30px;  
                            border: none;   
                            padding-left: 8px;  
                            margin-left: auto;
                            margin-right: auto;
                            border: 1px solid grey;
                            font-size: 17px; 
                            margin-top: 30px;
                            border-radius: 5px;
                        }

                        button{
                            width: 30vw; 
                            min-width: 90px; 
                            height: 30px;  
                            border: none;  
                            border-radius: 5px;  
                            color: white;  
                            background-color: #008037;
                            margin-top: 20pt;
                            margin-left: auto;
                            margin-right: auto;
                            font-size: 17px; 
                            cursor: pointer;
                        }
                    }

                    h3{
                        padding-top: 35px;
                        padding-bottom: 4%;
                        margin-left: auto;
                        margin-right: auto;
                        color: #008037;  
                        font-weight: 500;
                        font-size: 12pt;
                    }
                    
                    button{
                        width: 30vw;  
                        min-width: 90px;
                        height: 30px;  
                        border: none;  
                        border-radius: 5px;  
                        color: #008037;  
                        border: 1px solid #008037; 
                        margin-left: auto;
                        margin-right: auto;
                        font-size: 17px; 
                        cursor: pointer;
                        margin-top: 5px;
                    }
                }
            }
        }

        .middle{
            padding-top: 10vh;
            width: 100%;
            
            .left{
                h1{
                    font-size: 25pt;
                    font-weight: 400;
                    line-height: 150%;
                    color: #008037;
                    padding-top: 5vh;
                    margin-left: auto;
                    margin-right: auto;
                    width: 80vw;
                    text-align: center;
                }
            }
    
            .right{
                img{
                    display: block;
                    width: 80vw;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    padding-top: 10vh;
                }
            }
        }
    
        .bottom{
            padding-top: 10vh;
            width: 100%;
            
            h1{
                font-size: 25pt;
                font-weight: 400;
                line-height: 150%;
                color: #008037;
                padding-top: 5vh;
                margin-left: auto;
                margin-right: auto;
                width: 80vw;
                text-align: center;
            }
    
            table{
                padding-left: 8vw;
                padding-top: 5vh;
                th{
                    padding-top: 4%;
                    text-align: left;
                    font-size: 12pt;
                    color: #008037;
                    font-weight: 700;
                }
                td{
                    text-align: left;
                    width: 45vw;
                    padding-right: 8vw;
                    font-size: 10pt;
                    font-weight: 400;
                    line-height: 175%;
                }
    
            }
        
        }
    }
}
@media (min-width: 769px){
    .landing{
        text-align: center;
        width: 100%;

        .top{
            width: 100%; 
            display: flex;
            
            .left{
                flex: 50vw;
                h1{
                    font-size: 30pt;
                    font-weight: 600;
                    line-height: 150%;
                    color: #008037;
                    padding-top: 35%;
                    padding-left: 20%;
                    margin-right: 25vw;
                    width: 25vw;
                    text-align: center;
                }
            }

            .right{
                flex: 50vw;
                .loginbox{ 
                    margin-top: 13vh;
                    padding: 5%;
                    width: 30vw;
                    border: 1px solid grey;  
                    margin-left: 5vw;
                    max-height: 360px;
                    min-height: 360px;
                    border-radius: 10px;
                    

                    h2{
                        padding-top: 5%;
                        margin-left: auto;
                        margin-right: auto;
                        color: #008037;  
                    }
                    form{
                        input{
                            width: 20vw;  
                            height: 30px;  
                            border: none;   
                            padding-left: 8px;  
                            margin-left: auto;
                            margin-right: auto;
                            border: 1px solid grey;
                            font-size: 17px; 
                            margin-top: 30px;
                            border-radius: 5px;
                        }

                        button{
                            width: 10vw; 
                            min-width: 90px; 
                            height: 30px;  
                            border: none;  
                            border-radius: 5px;  
                            color: white;  
                            background-color: #008037;
                            margin-top: 20pt;
                            margin-left: auto;
                            margin-right: auto;
                            font-size: 17px; 
                            cursor: pointer;
                        }
                    }

                    h3{
                        padding-top: 35px;
                        padding-bottom: 4%;
                        margin-left: auto;
                        margin-right: auto;
                        color: #008037;  
                        font-weight: 500;
                        font-size: 12pt;
                    }
                    
                    button{
                        width: 10vw;  
                        min-width: 90px;
                        height: 30px;  
                        border: none;  
                        border-radius: 5px;  
                        color: #008037;  
                        border: 1px solid #008037; 
                        margin-left: auto;
                        margin-right: auto;
                        font-size: 17px; 
                        cursor: pointer;
                        margin-top: 5px;
                    }
                }
            }
        }
        
        .middle{
            padding-top: 10vh;
            width: 100%;
            display: flex;
            .left{
                flex: 50%;
                h1{
                    font-size: 30pt;
                    font-weight: 400;
                    line-height: 150%;
                    color: #008037;
                    padding-top: 45%;
                    padding-left: 8vw;
                    width: 30vw;
                    text-align: left;
                }
            }

            .right{
                flex: 50%;
                img{
                    width: 50vw;
                    max-width: 700px;
                    padding-right: 2vw;
                    padding-top: 10vh;
                }
            }
        }

        .bottom{
            padding-top: 10vh;
            width: 100%;
            
            h1{
                font-size: 30pt;
                font-weight: 400;
                line-height: 150%;
                color: #008037;
                padding-top: 5%;
                margin-right: 100px;
                width: 40vw;
                text-align: left;
                padding-left: 8vw;
            }

            table{
                padding-left: 8vw;
                th{
                    padding-top: 4%;
                    text-align: left;
                    font-size: 12pt;
                    color: #008037;
                    font-weight: 700;
                }
                td{
                    text-align: left;
                    width: 45vw;
                    padding-right: 8vw;
                    font-size: 10pt;
                    font-weight: 400;
                    line-height: 175%;
                }

            }
        
        }
    }
}