.login{ 
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow-x: hidden;
    h1{
        font-size: 25pt;
        font-weight: 400;
        padding-top: 50pt;
        color:#008037;
        width:100%;
        text-align: center;
        padding-bottom: 3%;
    }
    .loginbox{
        padding: 5%;
        width: 60vw;
        max-width: 500px;
        border: 1px solid grey;  
        margin-left: 40px;
        max-height: 320px;
        min-height: 320px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        
        form{
            input{
                width: 55vw; 
                max-width: 450px; 
                height: 30px;  
                border: none;   
                padding-left: 8px;  
                margin-left: auto;
                margin-right: auto;
                border: 1px solid grey;
                font-size: 17px; 
                border-radius: 5px;
                margin-bottom: 40px;
            }
            button{
                width: 20vw;
                max-width: 150px;  
                height: 30px;  
                border: none;  
                border-radius: 5px;
                color: white;  
                background-color: #008037;
                margin-left: auto;
                margin-right: auto;
                font-size: 17px; 
                cursor: pointer;
            }
        }
        h3{
            padding-top: 60px;
            padding-bottom: 2%;
            margin-left: auto;
            margin-right: auto;
            color: #008037;  
            font-weight: 500;
            font-size: 12pt;
        }
        
        button.register{
            width: 20vw;
            max-width: 150px;   
            height: 30px;  
            border: none;  
            border-radius: 5px;  
            color: #008037;  
            border: 1px solid #008037; 
            margin-left: auto;
            margin-right: auto;
            font-size: 17px; 
            cursor: pointer;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
}
