@media (max-width: 768px){   
    .home::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
  
    .home{
        .homedesktop{
            display: none;
        } 

        .homemobile::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }

        .homemobile{
            background-color: lightgrey;
            min-width: 100%;
            margin-left: 0;
            margin-right: 0;
            overflow-x: hidden;


            .body::-webkit-scrollbar { 
                display: none;  /* Safari and Chrome */
            }

            .body{
                display: flex;
                justify-content: space-between;
                width: 98.5vw;
                height: 115vh;
                
                .leftbarmobile{
                    z-index: 2;
                }

                .posts{
                    z-index: 1;
                    margin-left: auto;
                    margin-right: auto;
                    height: fit-content;
                }
                
            }
        } 
    }
}

@media (min-width: 769px){ 
    .home{

        .homedesktop::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }

        .homedesktop{
            background-color: lightgrey;
            min-width: 100%;
            margin-left: 0;
            margin-right: 0;
            overflow-x: hidden;

           
            .body{
                display: flex;
                justify-content: space-between;
                width: 50vw;
                height: 100vh;

                .leftbar{
                    z-index: 2;
                }
                .posts{
                    z-index: 1;
                    
                }
                .rightbar{
                    z-index: 0;
                }
            }
        } 

        .homemobile{
            display: none;
         }
    }
}