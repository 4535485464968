.homenavbar{
    align-items: center;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 2;
    height: 50px;
    margin-top: 0%;
    width: 100vw;

    .left{
        display: flex;
        align-items: center;
        width: max-content;
    
        img{
            max-width: 30px;
            min-width: 30px;
            padding-right: 5px;
            padding-left: 20px;
        }

        h3.logo{
            color: #008037;
            text-decoration: none;
            font-weight: 500;
            font-size: 14pt;
            padding-right: 5px;
            float:left;
        }
    }

    .center{
        display: flex;
        align-items: center;
        width: max-content;
        .search{
            input{
                width: 50vw;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                border: 1px solid grey;
                padding-left: 15px;
            }
        }
    }
    

    .right{
        display: flex;
        align-items: center;
        

        img{
            max-width: 25px;
            min-width: 25px;
            padding-right: 10px;
            padding-left: 10px;
            cursor: pointer;
        }
        img.profile{
            max-width: 25px;
            min-width: 25px;
            padding-right: 40px;
            padding-left: 10px;
            background-color: white;
        }

        .notification-dropdown {
            position: absolute;
            top: 65px;
            right: 60px;
            width: 250px;
            background-color: white;
            border: 2px solid grey;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 10px;
            z-index: 1;
            max-height: 30vh;
            border-radius: 15px;
          
            .notification-item {
              padding: 10px;
              display: flex;
              align-items: left;
          
              img {
                max-width: 25px;
                min-width: 25px;
                padding-right: 10px;
                padding-left: 10px;
              }
          
              p {
                font-size: 10pt;
                margin: 0;
              }
            }
          }
    }
}
