@media (max-width: 768px){  
    .navbar{
        list-style-type: none;
        padding: 0;
        width: 96vw;
        align-items: center;
        justify-content: space-between;
        
        display: flex;
        margin-top: 20px;
        min-width: 100%;
        margin-left: 0px;
        margin-right: 0;

        .left{
            display: flex;
            align-items: center;
        
            img{
                max-width: 25px;
                min-width: 25px;
                margin-right: 5px;
                padding-left: 20px;
            }

            h3{
                display: none;
            }

            h2{
                color: #008037;
                text-decoration: none;
                font-weight: 500;
                font-size: 14pt;
                padding-right: 5px;
                float: left;
            }
        }

        .right{
            display: flex;
            align-items: center;

            button.emptybox {
                border: 2px solid #008037;
                border-radius: 5px;
                color: #008037;
                font-size: 12pt;
                font-weight: 400;
                text-decoration: none;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 25px;
                padding-right: 25px;
                margin-right: 10px;
                cursor: pointer;
                height: 40px;
            }
            
            button.fullbox {
                border: 2px solid #008037;
                background-color: #008037;
                border-radius: 5px;
                color: white;
                font-size: 12pt;
                font-weight: 400;
                text-decoration: none;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 20px;
                padding-right: 20px;
                margin-right: 10px;
                cursor: pointer;
                height: 40px;
            }
        }
    }
}

@media (min-width: 769px){
    .navbar{
        list-style-type: none;
        padding: 0;
        width: 96vw;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        margin-top: 20px;

        .left{
            display: flex;
            align-items: center;
        
            img{
                max-width: 25px;
                min-width: 25px;
                margin-right: 5px;
                padding-left: 20px;
            }

            h3{
                color: #008037;
                text-decoration: none;
                font-size: 9pt;
                font-weight: 500;
                padding-left: 10px;
                padding-right: 10px;
            }

            h2{
                color: #008037;
                text-decoration: none;
                font-weight: 500;
                font-size: 14pt;
                padding-right: 5px;
                float: left;
            }
        }
        

        .right{
            display: flex;
            align-items: center;

            button.emptybox {
                border: 2px solid #008037;
                border-radius: 5px;
                color: #008037;
                font-size: 12pt;
                font-weight: 400;
                text-decoration: none;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 25px;
                padding-right: 25px;
                margin-right: 20px;
                cursor: pointer;
                height: 40px;
            }
            
            button.fullbox {
                border: 2px solid #008037;
                background-color: #008037;
                border-radius: 5px;
                color: white;
                font-size: 12pt;
                font-weight: 400;
                text-decoration: none;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 20px;
                padding-right: 20px;
                margin-right: 20px;
                cursor: pointer;
                height: 40px;
            }
        }
    }
}