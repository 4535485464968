@media (min-width: 769px){   
    .joblist {
      position: relative;
      top: 45px;
      left: 0px;
      
      .joblistdesktop{
        .container {
          .job{
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            border-radius: 20px;
            background-color: white;
            width: 50vw;
            margin-right: auto;
            margin-left: 19.5vw;
            margin-bottom: 20px;
            height: 200px;
            padding: 20px;
          }
        }
      }
  
      .joblistmobile{
        display: none;
      }
    }
  }